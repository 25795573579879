* {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.DraftEditor-root h1 {
  font-size: 24px !important;
  font-weight: 500 !important;
  margin: 10px 0;
}


.DraftEditor-root h2 {
  font-size: 22px !important;
  margin: 10px 0;
}


.DraftEditor-root h3 {
  font-size: 20px !important;
  margin: 10px 0 !important;
  font-weight: 400 !important;
}


.DraftEditor-root h4 {
  margin: 8px 0;
  font-size: 16px !important;
}

.DraftEditor-root h5 {
  margin: 5px 0;
  font-size: 14px !important;
}

.MuiTableCell-root {
  padding: 10px 4px !important;
}

.MuiTableCell-root:first-child {
  padding-left: 16px !important;
}

.MuiTableCell-root:last-child {
  padding-right: 12px !important;
}

.MuiTableHead-root .MuiSvgIcon-root {
  margin: 0 0 0 2px !important;
}

.MuiPaper-root {
  max-width: 600px !important;
}

.MuiTableSortLabel-root {
  line-height: 1.2 !important;
}
