a {
    color: inherit;
}

* {
    margin: 0;
    padding: 0;
}

*,
:before,
:after {
    box-sizing: border-box;
}
:focus {
    outline: none;
}
html {
    font-family: sans-serif;
    line-height: 1;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    text-rendering: optimizeLegibility;
    text-decoration-skip: objects;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
}
html * {
    max-height: 1000000px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    /* 1 */
    vertical-align: baseline;
    /* 2 */
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
    flex: none;
}

svg:not(:root) {
    overflow: hidden;
}

hr {
    height: 0;
    margin: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
}
input:required,
textarea:required {
    box-shadow: none;
}

button {
    overflow: visible;
    border: none;
    background: none;
}

button,
select {
    text-transform: none;
}

button,
html input[type='button'],
/* 1 */

input[type='reset'],
input[type='submit'] {
    /* 2 */
    cursor: pointer;

    -webkit-appearance: button;
    /* 3 */
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input {
    line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
    padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    height: auto;
}

input[type='search'] {
    -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

textarea {
    overflow: auto;
    resize: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

ol,
ul,
li {
    list-style: none;
}

button,
input,
textarea {
    border: none;
}

label {
    cursor: pointer;
}

svg {
    width: 100%;
    height: 100%;
}
